import { types, Instance, flow } from 'mobx-state-tree'
import Api from './Api'

const defaultProfilePhoto =
  'https://fdhq-assets.s3.amazonaws.com/widget/staff_members/images/user_icon.png'

const ProfilePhoto = types.model({
  x50: types.string,
  x100: types.string,
  x200: types.string,
  x400: types.string
})

type IProportions = 'x50' | 'x100' | 'x200' | 'x400'

export const StaffMember = types
  .model({
    id: types.number,
    name: types.string,
    bio: types.maybeNull(types.string),
    profile_photo: types.maybeNull(ProfilePhoto)
  })
  .views(self => ({
    profilePhoto: (proportions: IProportions) =>
      self.profile_photo ? self.profile_photo[proportions] : defaultProfilePhoto,
    bioOrNull: () => (self.bio ? self.bio : '')
  }))

export const StaffMemberStore = types
  .model({
    id: types.identifier,
    api: types.maybe(types.reference(Api)),
    staffMembers: types.optional(types.array(StaffMember), []),
    state: types.optional(types.enumeration('State', ['loading', 'done', 'error']), 'loading'),
    configuredStaffMemberId: types.maybe(types.number)
  })
  .actions(self => {
    const fetchStaffMembers = flow(function* fetchStaffMembers() {
      if (!self.api) return
      self.state = 'loading'

      try {
        const data = yield self.api.get('front/staff_members')
        self.state = 'done'
        self.staffMembers = data.staff_members
      } catch (e) {
        console.error(e)
        self.state = 'error'
      }
    })

    return {
      fetchStaffMembers
    }
  })
  .views(self => {
    const staffMemberById = (id: number) => {
      return self.staffMembers.find(staffMember => staffMember.id === id)
    }
    
    const configuredStaffMember = () => {
      if (self.configuredStaffMemberId) return staffMemberById(self.configuredStaffMemberId)
    }

    const staffMemberIdParam = () => {
     return !!self.configuredStaffMemberId ? `&staff_member_ids=${self.configuredStaffMemberId}` : ``
    }

    return {
      staffMemberById,
      configuredStaffMember,
      staffMemberIdParam
    }
  })

export type IStaffMember = Instance<typeof StaffMember>
export default StaffMemberStore
