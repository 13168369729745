import { types, flow, Instance } from 'mobx-state-tree'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import Api from './Api'
import DateStore from './DateStore'
import LocationStore from './LocationStore'
import ServiceStore from './ServiceStore'
import { StaffMemberStore } from './StaffMemberStore'
import { addDays } from 'date-fns'

export const IdAndName = types.model({
  id: types.number,
  name: types.maybe(types.string)
})

export const IdAndNameAndTZ = types.model({
  id: types.number,
  name: types.string,
  timezone: types.string
})

export const Slot = types
  .model({
    duration_in_minutes: types.number,
    start_at: types.string,
    end_at: types.string,
    date: types.string,
    service: IdAndName,
    location: IdAndNameAndTZ,
    staff_member: IdAndName
  })
  .views(self => ({
    startAt: (timezone?: string) => {
      if (!timezone) {
        return ''
      }

      return format(utcToZonedTime(toDate(self.start_at), timezone), 'h:mm a').toLowerCase()
    },
    endAt: (timezone?: string) => {
      if (!timezone) {
        return ''
      }

      return format(utcToZonedTime(toDate(self.end_at), timezone), 'h:mm a').toLowerCase()
    },
    key: () => `${self.service.id}-${self.location.id}-${self.staff_member.id}-${self.start_at}`
  }))

export const AvailabilityStore = types
  .model({
    api: types.maybe(types.reference(Api)),
    dateStore: types.maybe(types.reference(DateStore)),
    locationStore: types.maybe(types.reference(LocationStore)),
    serviceStore: types.maybe(types.reference(ServiceStore)),
    staffMemberStore: types.maybe(types.reference(StaffMemberStore)),
    selectedDate: types.optional(types.Date, new Date()),
    _slots: types.optional(types.array(Slot), []),
    state: types.optional(types.enumeration('State', ['loading', 'done', 'error']), 'loading')
  })
  .views(self => {
    const slots = () => {
      const selectedServiceId = self.serviceStore?.selectedService()?.id
      const selectedLocationId = self.locationStore?.selectedLocation()?.id
      return self._slots.filter(
        s => selectedServiceId === s.service.id && s.location.id === selectedLocationId
      )
    }

    const upcomingSlots = () => {
      const now = new Date()
      return slots().filter((s: ISlot) => new Date(s.start_at) >= now)
    }

    const hasAvailability = () => slots().length > 0

    return {
      slots,
      upcomingSlots,
      hasAvailability,
      selectedDateShortString: () => format(self.selectedDate, 'EE MM/d'),
      selectedDateString: () => format(self.selectedDate, 'EEEE, MMM do')
    }
  })
  .actions(self => {
    const fetch = flow(function* fetch(daysFromNow?: number) {
      if (!self.api) {
        self.state = 'error'
        console.error('API is not yet defined')
        return
      }

      const serviceId = self.serviceStore?.selectedService()?.id
      if (!serviceId) {
        console.error('Service Id required')
        return
      }

      const staffMemberIdParam = self.staffMemberStore?.staffMemberIdParam()

      try {
        self.state = 'loading'
        const date = format(!!daysFromNow ? addDays(self.selectedDate, daysFromNow) : self.selectedDate, 'yyyy-MM-dd')
        const data: any = yield self.api.get(
          `front/appointments/${serviceId}/available_slots`,
          `&date=${date}${staffMemberIdParam}`
        )
        if (!daysFromNow) self.state = 'done'
        self._slots = data.available_slots.sort((a: ISlot, b: ISlot) => {
          return new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
        })
      } catch (e) {
        console.error(e)
      }
    })

    const setState = (state: 'loading' | 'done' | 'error') => {
      self.state = state
    }

    const setDate = (date: Date) => {
      self.selectedDate = date
      fetch()
    }

    const nextAvailableDate = async (daysFromNow: number): Promise<Date | undefined> => {
      await fetch(daysFromNow)
      const slots = self.upcomingSlots()
      const dateOfFirstAvailable = !!slots.length ? slots[0]?.date : undefined
      if (!!dateOfFirstAvailable) {
        return toDate(dateOfFirstAvailable)
      } else {
        return undefined
      }
    }

    return {
      fetch,
      setState,
      setDate,
      nextAvailableDate,
      setApi: (api: any) => {
        self.api = api
      }
    }
  })

export type ISlot = Instance<typeof Slot>
export default AvailabilityStore
