import { types, Instance } from 'mobx-state-tree'
import { addMinutes, startOfDay } from 'date-fns'

const Service = types
  .model({
    id: types.number,
    name: types.string,
    type: types.string,
    position: types.integer,
    description: types.maybeNull(types.string),
    description_short: types.maybeNull(types.string),
    instructions: types.maybeNull(types.string),
    duration_in_minutes: types.integer,
    enrollment_window_blackout_minutes: types.maybeNull(types.integer)
  })
  .views(self => {
    return {
      getStartDate: () => {
        return startOfDay(addMinutes(new Date(), self.enrollment_window_blackout_minutes || 0))
      }
    }
  })
export type IService = Instance<typeof Service>
export default Service
