import React from 'react';
import StaffMember from './StaffMember'
import { observer } from 'mobx-react';
import { IStaffMember } from "../store/StaffMemberStore"
import Loader from './presentational/Loader';
import { useStore } from '../store/Provider';

const StaffMembers = () => {
  const { staffMemberStore } = useStore().store
  if (staffMemberStore.state === "loading") {
    return <Loader></Loader>
  }

  return (<div>
    {staffMemberStore.staffMembers.map((s: IStaffMember) => <StaffMember key={s.id} staff_member={s} />)}
  </div>)
}

export default observer(StaffMembers);
