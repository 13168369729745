import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

let mountings = {}

window.Pike13Widget = config => {
  if (!config.type) config.type = 'Schedule'
  const element = document.getElementById(config.elementId)
  let tinyMobileWidth = 385
  let mobileWidth = 640

  if (!element) {
    console.error(`element ${config.elementId} not found.`)
  }

  if (element.clientWidth) {
    const elementPercent = element.clientWidth / window.innerWidth
    tinyMobileWidth = tinyMobileWidth / elementPercent
    mobileWidth = mobileWidth / elementPercent
  } else {
    tinyMobileWidth = tinyMobileWidth * 10
    mobileWidth = mobileWidth * 10
  }

  if (mountings[config.elementId]) {
    ReactDOM.unmountComponentAtNode(element)
  }

  mountings[config.elementId] = true

  const mediaQueries = {
    tinyMobileWidth,
    mobileWidth
  }

  ReactDOM.render(
    <App
      component={config.type}
      subdomain={config.subdomain}
      timezone={config.timezone}
      staffMemberId={config.staffMemberId}
      useHashRoutes={config.useHashRoutes}
      openInTab={config.openInTab}
      style={config.style}
      mediaQueries={mediaQueries}
      locationIds={config.locationIds}
      serviceIds={config.serviceIds}
      date={config.date}
      oauthToken={config.oauthToken}
      defaultWidgetView={config.defaultWidgetView}
    />,
    element
  )
}
