import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import Calendar from '../Calendar'
import Slots from './Slots'
import { IoIosArrowBack } from 'react-icons/io'
import { useStore } from '../../store/Provider'
import Link from '../presentational/Link'

const BackButtonText = styled.span`
  margin-left: 5px;
`

const BackButton = () => {
  return (
    <Link className="pike13-back-button" to="/appointments/servicelocations">
      <IoIosArrowBack />
      <BackButtonText>Back</BackButtonText>
    </Link>
  )
}

const Availabilities = () => {
  const { availabilityStore, serviceStore, locationStore } = useStore().store
  const selectedService = serviceStore.selectedService()
  if (!selectedService) return <div>Must select a service</div>
  if (!locationStore.selectedLocation()) return <div>Must select a location</div>

  return (
    <div>
      <BackButton />
      <Calendar
        viewType="day"
        startDate={selectedService.getStartDate()}
        selectedDate={availabilityStore.selectedDate}
        onSetDate={availabilityStore.setDate}
      />
      <Slots />
    </div>
  )
}
export default observer(Availabilities)
