import styled from 'styled-components'

const Row = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.textColor};
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin: 7px 5px 5px 5px;
  border-radius: 5px;
  line-height: 16px;

  &.pike13-event, &.pike13-appointment {
    @media (max-width: ${props => props.theme.mobileWidth}px) {
      padding: 15px;
      display: grid;
      grid-template-columns: 30% 60% 10%;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    }
  }

  &.pike13-collapsed {
    box-shadow: 2px -2px 0 rgba(0, 0, 0, 0.1), 3px -4px #f3f3f3, 4px -6px 0 rgba(0, 0, 0, 0.1),
      5px -8px 0 #f3f3f3, 6px -10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    background: #f3f3f3;
    color: rgb(160, 160, 160);
  }

  &.pike13-past {
    background: #f3f3f3;
    color: rgb(160, 160, 160);
    cursor: pointer;
    boxshadow: '0px 0px 2px rgba(0, 0, 0, 0.1)';
  }

  > div {
    flex: 1;
  }
`
export default Row
