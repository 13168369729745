import React from 'react'
import { observer } from 'mobx-react'
import Schedule from './Schedule/Schedule'
import { useParams } from 'react-router-dom'
import Link from './presentational/Link'
import styled from 'styled-components'
import { useStore } from '../store/Provider'
import Loader from './presentational/Loader'
import { IoIosArrowBack } from 'react-icons/io'
import Only from './presentational/Only'
import { IStaffMember } from '../store/StaffMemberStore'

const StaffName = styled.div`
  font-weight: bold;
  font-size: 1.4em;
  padding: 5px;

  @media (max-width: 600px) {
    line-height: 1 ;
    text-align: center;
  }
`
const StaffImage = ({ staffMember, size }: { staffMember: IStaffMember; size: 64 | 125 | 200   }) => {
  const sizeString = size === 200 ? 'x200' : 'x400'

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `5px`,
  }

  return <img style={style} alt="" src={staffMember.profilePhoto(sizeString)} />
}

const StaffProfileButton = ({ staffMemberId }: { staffMemberId: number }) => {
  return (
    <div style={{ padding: '12px 5px' }}>
        <Link style={{ display: 'inline-block' }} to={`/staff/${staffMemberId}`}>
          View Profile
        </Link>
    </div>
  )
}

const StaffMemberSchedule = () => {
  const { staffMemberStore } = useStore().store
  const { id, viewType } = useParams()

  if (!id) return null

  const staffMemberId = parseInt(id)

  const staffMember = staffMemberStore.staffMembers.find(s => s.id === staffMemberId)

  if (staffMemberStore.state === 'loading') return <Loader />

  if (!staffMember)
    return (
      <div>
        Staff Member not found
      </div>
    )

  return (
    <div>
      <div style={{ padding: '12px 5px' }}>
        <Link
          style={{
            backgroundColor: 'transparent',
            color: '#555',
            border: 'none',
            padding: 0,
            display: 'flex',
            alignItems: 'center'
          }}
          to="/staff"
        >
          <IoIosArrowBack />
          &nbsp;&nbsp;<span>Back</span>
        </Link>
      </div>

      <div>
        <div style={{ padding: '2px' }}>
          <Only.Desktop>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: "5px" }}>
              <StaffImage staffMember={staffMember} size={64} />
              <StaffName style={{ fontSize: '24px', marginLeft: '10px' }}>{staffMember.name}</StaffName>
            </div>
            <StaffProfileButton staffMemberId={staffMemberId}></StaffProfileButton>
          </Only.Desktop>

          <Only.Mobile>
            <div style={{ display: 'flex' }}>
              <div style={{width: "50vw", display: "flex", justifyContent: "center"}}>
                <StaffImage staffMember={staffMember} size={125}/>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  width: "50vw",
                  alignItems: "center"
                }}
              >
                <StaffName>{staffMember.name}</StaffName>
                <div style={{ minHeight: '70px' }}>
                  <StaffProfileButton staffMemberId={staffMemberId}></StaffProfileButton>
                </div>
              </div>
            </div>
          </Only.Mobile>
        </div>
      </div>

      <div style={{ paddingTop: '1em' }}>
        <div>
          <Schedule staffMemberId={staffMemberId} viewType={viewType as 'week' | undefined} />
        </div>
      </div>
    </div>
  )
}

export default observer(StaffMemberSchedule)
