import { THEMES } from './themes';

export type Theme = {
  primaryColor: string,
  secondaryColor: string,
  backgroundColor: string,
  disabledBackground: string,
  onInteractivePrimary: string,
  surface: string,
  onPrimary: string,
  primaryOnBackground: string,
  primaryOnSurface: string,
  onSecondary: string,
  secondaryOnBackground: string,
  secondaryOnSurface: string,
  onDisabledBackground: string,
  success: string,
  critical: string,
  warningText: string,
  warningIcon: string,
  interactivePrimary: string,
  interactiveSecondary: string,
  divider: string,
  banner: string
}

export type ThemeKey = keyof Theme;
export interface Themes {
  [key: string]: Theme
}

export const keys = [
  "primaryColor",
  "secondaryColor",
  "backgroundColor",
  "disabledBackground",
  "onInteractivePrimary",
  "surface",
  "onPrimary",
  "primaryOnBackground",
  "primaryOnSurface",
  "onSecondary",
  "secondaryOnBackground",
  "secondaryOnSurface",
  "onDisabledBackground",
  "success",
  "critical",
  "warningText",
  "warningIcon",
  "interactivePrimary",
  "interactiveSecondary",
  "divider",
  "banner"
];

export const themes = THEMES as Themes;

export const basicProperties = [
  "primaryColor",
  "onPrimary",
  "interactivePrimary",
  "onInteractivePrimary",
  "backgroundColor"
];