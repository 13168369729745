import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from '../../store/Provider'
import { Link } from 'react-router-dom'
import { lighten } from 'polished'
import Select from 'react-select'
import map from 'ramda/src/map'
import Only from '../presentational/Only'
import { max } from 'date-fns'

const ServiceLocationContainer = styled.div`
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70h;
  min-height: 380px;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex 1;
  justify-content: center;
  padding: 10px

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    width: 100%;
  }
`

const SelectorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 10px;
`

const ServiceLocationText = styled.div`
  width: 80px;
  text-align: end;
  font-size: 14px;
  color: #9d9d9d;
`

const Selector = styled(Select)`
  &.pike13-select {
    margin-left: 10px;
    display: flex;
    justify-content: center;

    @media (max-width: ${props => props.theme.mobileWidth}px) {
      width: 100%;
      margin-left: 0px;
    }
  }

  &.pike13-select > .pike13__control {
    width: 450px;
    background-color: #ffffff;
    border-radius: 28px;
    border: none;
    box-shadow: none;

    @media (max-width: ${props => props.theme.mobileWidth}px) {
      width: 100%;
    }
  }

  &.pike13-select > .pike13__control > .pike13__value-container > .pike13__placeholder {
    font-size: 14px;
    color: #545759;
    margin-left: 10px;
  }

  &.pike13-select > .pike13__control > .pike13__indicators > .pike13__indicator-separator {
    display: none;
  }

  &.pike13-select > .pike13__control > .pike13__value-container > .pike13__single-value {
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }

  &.pike13-select > .pike13__menu > .pike13__menu-list > .pike13__option {
    border: none;
    font-size: 14px;
    color: #545759;
  }

  &.pike13-select > .pike13__menu > .pike13__menu-list > .pike13__option--is-focused {
    font-size: 14px;
    background: #ffffff;

    &:hover {
      background: ${props => props.theme.businessColor};
    }
  }

  &.pike13-select > .pike13__menu > .pike13__menu-list > .pike13__option--is-selected {
    font-size: 14px;
    background: white;
    color: inherit;

    &:hover {
      background: ${props => props.theme.businessColor};
    }
  }

  &.pike13-select > .pike13__menu {
    margin-top: 2px;
    width: 95%;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }
`
const LinkContainer = styled(Link)`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    height: 40px;
  }
`

const DisabledLinkContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  cursor: not-allowed;
  margin-top: 30px;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    height: 40px;
  }
`

const LocationServiceLink = styled.div`
  background: ${props => props.theme.businessColor};
  color: white;
  height: 40px;
  border-radius: 28px;
  width: 448px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  margin-top: 30px;

  &:hover {
    background: ${props => lighten(0.07, props.theme.businessColor)};
  }

  &.disabled-link {
    background: #cccccc;
  }

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    margin: 10px;
    width: 100%;
  }
`
const ServiceLocation = () => {
  interface SelectorLocation {
    id: number
    name: string
    address: string
  }
  interface SelectorService {
    id: number
    name: string
  }

  const { serviceStore, locationStore, availabilityStore, dateStore } = useStore().store
  const locations = map((value: { id: number; name: string; address: string | null }) => {
    return {
      id: value.id,
      value: value.id,
      label: `${value.name} (${value.address ? value.address : 'Address unavailable'})`
    }
  }, locationStore.locations())

  const appointmentServices = map((value: { id: number; name: string }) => {
    return {
      id: value.id,
      value: value.id,
      label: value.name
    }
  }, serviceStore.filteredAppointments())

  const setSelectedLocationId = (location: SelectorLocation) =>
    locationStore.setSelectedLocationId(location.id)

  const setSelectedServiceId = (service: SelectorService) => {
    serviceStore.setSelectedServiceId(service.id)
    const selectedService = serviceStore.selectedService()
    if (!selectedService) return

    availabilityStore.setDate(max([selectedService.getStartDate(), dateStore.startDate()]))
  }

  const formValid =
    !!serviceStore.selectedService() &&
    (!!locationStore.selectedLocation() || !locationStore.multipleLocations())

  return (
    <ServiceLocationContainer>
      <OptionsContainer>
        <div style={{flex: 4, marginTop: 40}}/>
        {locationStore.multipleLocations() && (
          <SelectorContainer className="pike13-location-selector">
            <Only.Desktop>
              <ServiceLocationText>Locations</ServiceLocationText>
            </Only.Desktop>
            <Selector
              placeholder={
                locationStore.selectedLocation()?.name
                  ? locationStore.selectedLocation()?.name
                  : 'Select Location'
              }
              className="pike13-select"
              classNamePrefix="pike13"
              options={locations}
              onChange={setSelectedLocationId}
            ></Selector>
          </SelectorContainer>
        )}
        <SelectorContainer className="pike13-service-selector">
          <Only.Desktop>
            <ServiceLocationText>Service</ServiceLocationText>
          </Only.Desktop>
          <Selector
            placeholder={
              serviceStore.selectedService()?.name
                ? serviceStore.selectedService()?.name
                : 'Select Service'
            }
            className="pike13-select"
            classNamePrefix="pike13"
            options={appointmentServices}
            onChange={setSelectedServiceId}
          >
            Hello
          </Selector>
        </SelectorContainer>
          {formValid ? (
            <LinkContainer to="/appointments/availabilities">
              <LocationServiceLink>Continue</LocationServiceLink>
            </LinkContainer>
          ) : (
            <DisabledLinkContainer>
              <LocationServiceLink className="disabled-link">Continue</LocationServiceLink>
            </DisabledLinkContainer>
          )}
        <SelectorContainer/>
      </OptionsContainer>
    </ServiceLocationContainer>
  )
}

export default observer(ServiceLocation)
