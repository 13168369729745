import React from 'react'
import { observer } from 'mobx-react'
import { Switch, Route } from 'react-router-dom'
import ServiceLocation from './ServiceLocation'
import Availabilities from './Availabilities'

const Appointments = () => {
  return (
    <Switch>
      <Route path="/appointments/availabilities">
        <Availabilities />
      </Route>
      <Route path="/appointments/">
        <ServiceLocation />
      </Route>
    </Switch>
  )
}

export default observer(Appointments)
