import { types, flow, Instance } from 'mobx-state-tree'
import LocationStore from './LocationStore'
import ServiceStore from './ServiceStore'
import EventStore from './EventStore'
import { StaffMemberStore } from './StaffMemberStore'
import { Business } from './Business'
import Api from './Api'
import DateStore from './DateStore'
import AvailabilityStore from './AvailabilityStore'
import BrandingStore from './BrandingStore'

const Store = types
  .model({
    dateStore: types.optional(DateStore, { id: '1' }),
    // HACK: not sure the best practice for this, i want a singleton
    // api and will also immediately set subdomain.
    internal: types.optional(types.boolean, false),
    oauthToken: types.optional(types.string, ''),
    subdomain: types.string,
    api: types.optional(Api, { id: '1', subdomain: 'www', oauthToken: '' }),
    eventStore: types.late(() => types.optional(EventStore, {})),
    locationStore: types.late(() => types.optional(LocationStore, { id: '1' })),
    serviceStore: types.late(() => types.optional(ServiceStore, { id: '1' })),
    availabilityStore: types.late(() => types.optional(AvailabilityStore, {})),
    staffMemberStore: types.late(() => types.optional(StaffMemberStore, { id: '1' })),
    state: types.optional(types.enumeration('State', ['loading', 'done', 'error']), 'loading'),
    business: types.maybe(Business),
    openInTab: types.optional(types.boolean, true),
    widgetView: types.optional(types.enumeration("WidgetView", ['week', 'day']), 'week'),
    brandingStore: types.late(() => types.optional(BrandingStore, {theme: {}}))
  })
  .actions(self => {
    // TODO: move api down to all the stores or create a singleton
    const api = self.api

    // not sure if there's not a more canonical way to have these
    // stores talk, but this does the job
    self.api.subdomain = self.subdomain
    self.api.oauthToken = self.oauthToken
    self.eventStore.api = api
    self.brandingStore.api = api
    self.eventStore.dateStore = self.dateStore
    self.eventStore.staffMemberStore = self.staffMemberStore
    self.availabilityStore.dateStore = self.dateStore
    self.availabilityStore.locationStore = self.locationStore
    self.availabilityStore.serviceStore = self.serviceStore
    self.availabilityStore.staffMemberStore = self.staffMemberStore
    self.locationStore.api = api
    self.serviceStore.api = api
    self.staffMemberStore.api = api
    self.availabilityStore.api = api

    const fetchBusiness = flow(function* fetchBusiness() {
      try {
        self.state = 'loading'
        const data = yield api.get('front/business')
        self.business = data.businesses[0]
        if (!self.dateStore.timezone && self.business) {
          self.dateStore.setTimezone(self.business.timezone)
        }
        self.state = 'done'
      } catch (e) {
        console.error(e)
        self.state = 'error'
      }
    })

    const setWidgetView = (widgetView: 'week' | 'day') => {
      self.widgetView = widgetView
    }  

    return {
      fetchBusiness,
      setWidgetView
    }
  })

  
// export type IStore = Instance<typeof Store>
export interface IStore extends Instance<typeof Store> {}
export default Store
