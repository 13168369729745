import { Instance, types, flow } from 'mobx-state-tree'
import Api from './Api'
import { default as _ } from "lodash";

export const Theme = types.model({
  primaryColor: types.optional(types.string, '#00833d'),
  secondaryColor: types.optional(types.string, '#81bc00'),
  backgroundColor: types.optional(types.string, '#e9edee'),
  disabledBackground: types.optional(types.string, '#f4f4f4'),
  onInteractivePrimary: types.optional(types.string, '#ffffff'),
  surface: types.optional(types.string, '#ffffff'),
  onPrimary: types.optional(types.string, '#ffffff'),
  primaryOnBackground: types.optional(types.string, '#000000'),
  primaryOnSurface: types.optional(types.string, '#000000'),
  onSecondary: types.optional(types.string, '#000000'),
  secondaryOnBackground: types.optional(types.string, '#707070'),
  secondaryOnSurface: types.optional(types.string, '#707070'),
  onDisabledBackground: types.optional(types.string, '#707070'),
  success: types.optional(types.string, '#00833d'),
  critical: types.optional(types.string, '#eb0000'),
  warningText: types.optional(types.string, '#ac5d00'),
  warningIcon: types.optional(types.string, '#dc8400'),
  interactivePrimary: types.optional(types.string, '#00833d'),
  interactiveSecondary: types.optional(types.string, '#707070'),
  divider: types.optional(types.string, '#c2c2c4'),
  banner: types.optional(types.string, '#e5f0ea'),
});

const transformKeys = (hash: any, transformer: (s:string) => string) => {
  const r: any = {};
  Object.keys(hash).forEach((key) => {
    r[transformer(key)] = hash[key];
  });
  return r;
};

const transformValues = (hash: any, transformer: (s:string) => string) => {
  const r: any = {};
  Object.keys(hash).forEach((key) => {
    r[key] = transformer(hash[key]);
  });
  return r;
};

export const BrandingStore = types
  .model({
    api: types.maybe(types.reference(Api)),
    theme: types.late(() => Theme),
    state: types.optional(
      types.enumeration('State', ['preloading', 'loading', 'done', 'error']),
      'preloading'
    ),
  })
  .actions((self) => {
    const fetchBranding = flow(function* fetchBranding() {
      if (!self.api) {
        self.state = 'error'
        console.error('API is not yet defined')
        return
      }
      try {
        self.state = 'loading';
        const brandingData = yield self.api.apiFetch('desk/branding', { method: "GET" });
        if (brandingData && brandingData.theme && brandingData.theme['primaryColor'] !== null)
          self.theme = transformValues(transformKeys(brandingData.theme, _.camelCase), (v:string) => `#${v}`);
        self.state = 'done';
      } catch (e) {
        console.log(e, `Error fetching branding.theme`);
        self.state = 'error';
      }
    });

    const updateTheme = flow(function* updateTheme(theme:ITheme) {
      if (!self.api) {
        self.state = 'error'
        console.error('API is not yet defined')
        return
      }
      
      try {
        self.state = 'loading';
        const brandingData = yield self.api.apiFetch(`desk/branding`, {
          method: "PUT",
          data: { colors: transformValues(transformKeys(theme, _.snakeCase), (v:string) => v.replace('#', '')) }
        });
        if (brandingData.theme['primaryColor'] !== null)
          self.theme = transformValues(transformKeys(brandingData.theme, _.camelCase), (v:string) => `#${v}`);
        self.state = 'done';
      } catch (e) {
        console.log(e, 'Error updating theme');
        self.state = 'error';
      }
    })
    //TODO: Add views, preloading, & match store model naming convention
    return { fetchBranding, updateTheme };
  });

export interface ITheme extends Instance<typeof Theme> {}

export default BrandingStore;