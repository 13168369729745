import React from 'react'
import { observer } from 'mobx-react'
import { IStaffMember } from '../store/StaffMemberStore'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useStore } from '../store/Provider'
import Link from './presentational/Link'
import { IoIosArrowBack } from 'react-icons/io'
import Loader from './presentational/Loader'
import Only from './presentational/Only'

const StaffName = styled.div`
  line-height: 28px;
  font-size: 24px;
  padding-left: 0.75em;
  padding-right: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
`

const StaffNameMobile = styled.div`
  font-size: 20px;
  padding-left: 0.75em;
  padding-right: 5px;
  padding-top: 1em;
  padding-bottom: 5px;
`

const StaffProfileText = styled.div`
  margin-left: 2em;
  margin-right: 2em;
  img {
    max-width: 80%;
  }
`
const StaffProfileTextMobile = styled.div`
  margin-left: 3em;
  margin-right: 3em;
  img {
    max-width: 80%;
  }
`

const StaffImage = ({ staffMember, size }: { staffMember: IStaffMember; size: 64 | 100 }) => {
  const sizeString = size === 64 ? 'x50' : 'x100'

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    'border-radius': '5px'
  }

  return <img style={style} alt="" src={staffMember.profilePhoto(sizeString)} />
}

const ViewScheduleButton = ({ staffMemberId }: { staffMemberId: number }) => {
  return (
    <div style={{ padding: '15px 0px 15px 15px' }}>
      <Link style={{ display: 'inline-block' }} to={`/staff/${staffMemberId}/schedule`}>
        View Schedule
      </Link>
    </div>
  )
}

const StaffMemberProfile = () => {
  const { staffMemberStore } = useStore().store
  const { id } = useParams()

  if (!id) return null

  const staffMemberId = parseInt(id)

  const staffMember = staffMemberStore.staffMembers.find(s => s.id === staffMemberId)

  if (staffMemberStore.state === 'loading')
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    )

  if (!staffMember) return <div>Staff Member not found</div>

  return (
    <div>
      <div style={{ padding: '1em 1em .5em' }}>
        <Link
          style={{
            backgroundColor: 'transparent',
            color: '#555',
            border: 'none',
            padding: 0,
            display: 'flex',
            alignItems: 'center'
          }}
          to="/staff"
        >
          <IoIosArrowBack />
          &nbsp;&nbsp;<span>Back to Staff List</span>
        </Link>
      </div>

      <Only.Desktop>
        <div style={{ width: '65%', margin: 'auto' }}>
          <div>
            <div style={{ padding: '1em 1em .5em' }}>
              <div style={{ paddingLeft: '1em', display: 'flex', alignItems: 'center' }}>
                <StaffImage staffMember={staffMember} size={64} />
                <StaffName>{staffMember.name}</StaffName>
              </div>
              <ViewScheduleButton staffMemberId={staffMember.id} />
            </div>
          </div>
          <StaffProfileText>
            <span dangerouslySetInnerHTML={{ __html: staffMember.bioOrNull() }} />
          </StaffProfileText>
        </div>
      </Only.Desktop>

      <Only.Mobile>
        <div>
          <div style={{ padding: '1em 1em .5em' }}>
            <div style={{ paddingLeft: '1em', display: 'flex' }}>
              <StaffImage staffMember={staffMember} size={100} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly'
                }}
              >
                <StaffNameMobile>{staffMember.name}</StaffNameMobile>
                <ViewScheduleButton staffMemberId={staffMember.id} />
              </div>
            </div>
          </div>
        </div>
        <StaffProfileTextMobile>
          <span dangerouslySetInnerHTML={{ __html: staffMember.bioOrNull() }} />
        </StaffProfileTextMobile>
      </Only.Mobile>
    </div>
  )
}

export default observer(StaffMemberProfile)
