import React, { useEffect } from 'react'
import Calendar from '../Calendar'
import { observer } from 'mobx-react'
import Events from './Events'
import { useStore } from '../../store/Provider'
import styled from 'styled-components'

const ScheduleContainer = styled.div`
`

const Schedule = ({
  staffMemberId,
}: {
  staffMemberId?: number
  viewType?: 'day' | 'week'
}) => {
  const { eventStore, dateStore, staffMemberStore, widgetView } = useStore().store
  useEffect(() => {
    !!widgetView ? eventStore.setViewType(widgetView as 'day' | 'week') : eventStore.setViewType('week')
  }, [widgetView, staffMemberId, eventStore])

  const showWeekTab = !(dateStore.weekdays?.length === 1)
  const staffMemberIdForEvents = staffMemberId ? staffMemberId: staffMemberStore.configuredStaffMemberId

  return (
    <ScheduleContainer>
      <Calendar
        viewType={ widgetView as 'day' | 'week'  }
        selectedDate={ eventStore.selectedDate }
        onSetDate={ eventStore.setDate }
        showWeekTab={ showWeekTab }
      />
      <Events staffMemberId={ staffMemberIdForEvents } />
    </ScheduleContainer>
  )
}
export default observer(Schedule)
