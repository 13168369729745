import { types, Instance, flow } from 'mobx-state-tree'
import Api from './Api'
import Service, { IService } from './Service'

const ServiceStore = types
  .model({
    id: types.identifier,
    api: types.maybe(types.reference(Api)),
    state: types.optional(
      types.enumeration('State', ['preload', 'loading', 'done', 'error']),
      'preload'
    ),
    _services: types.optional(types.array(Service), []),
    configuredServiceIds: types.optional(types.array(types.number), []),
    selectedServiceId: types.maybe(types.number)
  })
  .actions(self => {
    const fetchServices = flow(function* fetchServices() {
      if (!self.api) return

      try {
        self.state = 'loading'
        const data = yield self.api.get('front/services')
        self._services = data.services
        self.state = 'done'
      } catch (e) {
        console.error(e)
        self.state = 'error'
      }
    })

    const setSelectedServiceId = (serviceId: number) => {
      self.selectedServiceId = serviceId
    }

    return {
      fetchServices,
      setSelectedServiceId
    }
  })
  .views(self => {
    const filteredByConfiguredServices = () => {
      if (self.configuredServiceIds.length > 0)
        return self._services.filter(service => self.configuredServiceIds.includes(service.id))

      return self._services
    }

    const filteredAppointments = () => {
      return filteredByConfiguredServices()
        .filter(s => s.type === 'Appointment')
        .sort((serviceA: IService, serviceB: IService) => serviceA.position.valueOf() - serviceB.position.valueOf());
    }

    const services = () => {
      if (self.state === 'preload') self.fetchServices()
      return filteredByConfiguredServices()
    }

    const serviceById = (id: number) => {
      return self._services.find(l => l.id === id)
    }

    const selectedService = () => {
      if (self.selectedServiceId) return serviceById(self.selectedServiceId)
      if (services().length === 1) return serviceById(services()[0].id)
    }

    const hasServices = () => services().length > 0
    const multipleServices = () => services().length > 1
    const isReady = () => self.state === 'done'

    return {
      filteredByConfiguredServices,
      filteredAppointments,
      services,
      serviceById,
      selectedService,
      hasServices,
      multipleServices,
      isReady
    }
  })

export type IServiceStore = Instance<typeof ServiceStore>
export default ServiceStore
