import { types, Instance, flow } from 'mobx-state-tree'
import Api from './Api'

export const Location = types.model({
  id: types.number,
  name: types.string,
  position: types.number,
  slug: types.string,
  show_in_client_mode: types.boolean,
  timezone: types.string,
  timezone_friendly: types.string,
  address: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  country_code: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  formatted_address: types.maybeNull(types.string),
  hidden_at: types.maybeNull(types.boolean),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  phone: types.maybeNull(types.string),
  postal_code: types.maybeNull(types.string),
  state_code: types.maybeNull(types.string),
  street_address: types.maybeNull(types.string)
})

const LocationStore = types
  .model({
    id: types.identifier,
    api: types.maybe(types.reference(Api)),
    _locations: types.array(Location),
    state: types.optional(
      types.enumeration('State', ['preload', 'loading', 'done', 'error']),
      'preload'
    ),
    configuredLocationIds: types.optional(types.array(types.number), []),
    selectedLocationId: types.maybe(types.number)
  })
  .actions(self => {
    const fetchLocations = flow(function* fetchLocations() {
      if (!self.api) return

      try {
        self.state = 'loading'
        self._locations = (yield self.api.get('front/locations')).locations
        self.state = 'done'
      } catch (e) {
        console.error(e)
        self.state = 'error'
      }
    })

    const setSelectedLocationId = (locationId: number) => {
      self.selectedLocationId = locationId
    }

    return {
      fetchLocations,
      setSelectedLocationId
    }
  })
  .views(self => {
    const filteredByConfiguredLocations = () => {
      if (self.configuredLocationIds.length > 0)
        return self._locations.filter(location =>
          self.configuredLocationIds.includes(location.id)
        )

      return self._locations
    }

    const locations = () => {
      if (self.state === 'preload') self.fetchLocations()

      return filteredByConfiguredLocations()
    }

    const locationById = (id: number) => {
      return self._locations.find(l => l.id === id)
    }

    const selectedLocation = () => {
      if (self.selectedLocationId) return locationById(self.selectedLocationId)
      if (locations().length === 1) return locations()[0]
    }

    const hasLocations = () => locations().length > 0
    const multipleLocations = () => locations().length > 1
    const isReady = () => self.state === 'done'

    return {
      locations,
      locationById,
      selectedLocation,
      hasLocations,
      multipleLocations,
      isReady
    }
  })

export type ILocation = Instance<typeof Location>
export default LocationStore
