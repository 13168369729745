import { types, Instance } from "mobx-state-tree"
import { isInternal } from "../util";

type methodType = 'GET' | 'PUT' | 'POST';

export type fetchOptions = {
  queryString?: string;
  method?: methodType;
  data?: object;
  ignoreSubdomain?: boolean;
  nonAPI?: boolean,
  forceSubdomain?: string;
  parseResults?: (value: Response) => any;
};

type IFetchParams = {
  method: methodType;
  headers: any;
  body?: string;
};

const clientId = "KR8INAuWvDpEHEG6r12XUDo2wuEHLZya94hArhaV"

const Api = types.model({
  id: types.identifier,
  subdomain: types.string,
  oauthToken: types.string
}).views(self => {

  const apiFetch = (url: string, options?: fetchOptions): Promise<any> => {

    const internal = isInternal(window);

    let subdomainPart =
      self.subdomain && !options?.ignoreSubdomain ? `${self.subdomain}.` : '';
    if (options?.forceSubdomain) subdomainPart = `${options.forceSubdomain}.`;

    const defaults: fetchOptions = { queryString: '', method: 'GET', data: {} };
    const values = { ...defaults, ...options };
    if (options?.data) values.data = { ...defaults.data, ...options.data };
    const fetchParams: IFetchParams = {
      method: values.method || 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    if(!internal && self.oauthToken !== '') {
      fetchParams.headers['Authorization'] = `Bearer ${self.oauthToken}`
    }

    if (values.method === 'POST' || values.method === 'PUT') {
      fetchParams.body = JSON.stringify(values.data);
    }

    const domain = internal ? '' : `https://${subdomainPart}pike13.com`

    const endpoint = options?.nonAPI ? `${domain}${url}?client_id=${clientId}${values.queryString}` : `${domain}/api/v2/${url}?client_id=${clientId}${values.queryString}`

    console.log(endpoint,fetchParams)
    const parseResults = options?.parseResults || ((d: any) => d.json());
    return fetch(endpoint, fetchParams)
      .then(parseResults)
      .then((r) => {

        // Handle rails ActiveRecord errors
        if (r?.errors?.base) {
          alert(r.errors.base.join(', '));
        }
        return r;
      });
  };

  const get = (url: string, queryString?: string): Promise<any> => {
    if (!queryString) {
      queryString = ""
    }
    return fetch(`https://${self.subdomain}.pike13.com/api/v2/${url}.json?client_id=${clientId}${queryString}`).then(d => d.json())
  }

  return {
    get,
    fetch,
    apiFetch
  }
})
export type IApi = Instance<typeof Api>
export default Api