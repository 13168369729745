import { types, Instance } from "mobx-state-tree"

export const Business = types.model({
  id: types.number,
  name: types.string,
  subdomain: types.string,
  default_locale: types.string,
  currency_code: types.string,
  website: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  twitter: types.maybeNull(types.string),
  facebook: types.maybeNull(types.string),
  instagram: types.maybeNull(types.string),
  is_franchise: types.boolean,
  age_of_majority: types.number,
  is_active: types.boolean,
  is_in_trial: types.boolean,
  is_suspended: types.boolean,
  client_app: types.boolean,
  email_address: types.maybeNull(types.string),
  timezone: types.string
})

export type IBusiness = Instance<typeof Business>
