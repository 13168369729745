import { types, flow, Instance } from 'mobx-state-tree'
import addDays from 'date-fns/addDays'
import max from 'date-fns/max'
import { formatISO } from 'date-fns'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import startOfDay from 'date-fns/startOfDay'
import { StaffMember, StaffMemberStore } from './StaffMemberStore'
import Api from './Api'
import DateStore from './DateStore'
import pipe from 'ramda/src/pipe'
import groupBy from 'ramda/src/groupBy'

const TODAY = new Date()

const Waitlist = types.model({ full: types.boolean }).views(self => ({ isFull: () => self.full }));

export const Event = types
  .model({
    id: types.number,
    location_id: types.number,
    service_id: types.number,
    name: types.string,
    url: types.string,
    staff_members: types.array(StaffMember),
    start_at: types.string,
    end_at: types.string,
    capacity_remaining: types.maybeNull(types.number),
    state: types.string,
    full: types.boolean,
    waitlist: types.maybeNull(Waitlist)
  })
  .views(self => {
    const inPast = () => new Date() > new Date(self.start_at)
    const isCanceled = () => self.state === 'canceled'
    const capacityRemaining = () => !!self.capacity_remaining && self.capacity_remaining > 0
    return {
      startAt: (timezone?: string) => {
        if (!timezone) {
          return ''
        }

        return format(utcToZonedTime(toDate(self.start_at), timezone), 'h:mm aaaa')
          .replace(/\./g, '')
          .toLowerCase()
      },
      endAt: (timezone?: string) => {
        if (!timezone) {
          return ''
        }

        return format(utcToZonedTime(toDate(self.end_at), timezone), 'h:mm aaaa')
          .replace(/\./g, '')
          .toLowerCase()
      },
      staffMembers: () => self.staff_members.map(s => s.name).join(', '),
      inPast,
      isCanceled,
      isEnrollable: () => !isCanceled() && !self.full && !inPast(),
      showCapacity: () => !inPast() && capacityRemaining() && !isCanceled(),
      isFull: () => self.full && !isCanceled()
    }
  })

export const EventStore = types
  .model({
    api: types.maybe(types.reference(Api)),
    dateStore: types.maybe(types.reference(DateStore)),
    staffMemberStore: types.maybe(types.reference(StaffMemberStore)),
    selectedDate: types.optional(types.Date, new Date()),
    endDate: types.optional(types.Date, new Date()),
    serviceIds: types.maybe(types.array(types.number)),
    locationIds: types.maybe(types.array(types.number)),
    viewType: types.optional(types.enumeration('ViewType', ['day', 'week']), 'day'),
    _events: types.optional(types.array(Event), []),
    state: types.optional(
      types.enumeration('State', ['preloading', 'loading', 'done', 'error']),
      'preloading'
    )
  })
  .actions(self => {
    const fetchEvents = flow(function* fetchEvents(searching?: boolean) {
      if (!self.api) {
        self.state = 'error'
        console.error('API is not yet defined')
        return
      }

      const staffMemberIdParam = self.staffMemberStore?.staffMemberIdParam()

      try {
        self.state = 'loading'
        const from = formatISO(startOfDay(self.selectedDate))
        const toDate = searching ? 45 : self.viewType === 'week' ? 7 : 1
        const to = formatISO(startOfDay(addDays(self.selectedDate, toDate)))

        const data: any = yield self.api.get(
          'front/event_occurrences',
          `&from=${from}&to=${to}${staffMemberIdParam}`
        )

        const eventsSorted = data.event_occurrences.sort((a: IEvent, b: IEvent) => {
          return new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
        })
        if (!searching) self.state = 'done'
        self._events = eventsSorted
      } catch (e) {
        console.error(e)
      }
    })

    const setState = (state: 'preloading' | 'loading' | 'done' | 'error') => {
      self.state = state
    }

    const setDate = (date: Date) => {
      self.selectedDate = date
      fetchEvents()
    }

    const setViewType = (viewType: 'day' | 'week') => {
      self.viewType = viewType
      fetchEvents()
    }

    const defaultToFirstAvailableDate = () => {
      if (!self.dateStore) {
        return
      }

      if (self.selectedDate.toString() !== TODAY.toString()) return

      const startDate = max([new Date(), self.dateStore.startDate()])

      const nextDate = self.dateStore.nextDate(addDays(startDate, -1))

      if (nextDate) {
        self.selectedDate = nextDate
      }
    }

    const nextAvailableDate = async (staffMemberId?: number): Promise<Date | undefined> => {
      await fetchEvents(true);
      const eventsByService = self.serviceIds?.length ? self._events.filter(e => self.serviceIds?.includes(e.service_id)) : self._events
      const events = !!staffMemberId ? eventsByService.filter(s => s.staff_members.find(sm => sm.id === staffMemberId)) : eventsByService
      const dateOfFirstAvailable = !!events.length ? events[0].start_at : undefined
      if (!!dateOfFirstAvailable) {
        return toDate(dateOfFirstAvailable)
      } else {
        return undefined
      }
    }

    return {
      afterAttach: defaultToFirstAvailableDate,
      defaultToFirstAvailableDate,
      fetchEvents,
      nextAvailableDate,
      setState,
      setDate,
      setViewType,
      setApi: (api: any) => {
        self.api = api
      }
    }
  })
  .views(self => {
    const filteredEventsByService = (events: Array<IEvent>) => {
      if (self.state === 'preloading') self.fetchEvents()

      if (!self.serviceIds) return self._events

      return self._events.filter(e => self.serviceIds?.includes(e.service_id))
    }

    const filterEventsByLocation = (events: Array<IEvent>) => {
      if (!self.locationIds || !self.locationIds.length) {
        return events
      }
      return events.filter(e => self.locationIds?.includes(e.location_id))
    }

    const selectedWeekString = () => {
      const start = format(self.selectedDate, 'EEEE, MMM do');
      const end = format(addDays(self.selectedDate, 6), 'EEEE, MMM do');

      return `${start} to ${end}`
    }


    const eventsFor = (staffMemberId?: number) => {
      const filterValidDates = (events: Array<IEvent>) => {
        return events.filter(
          e => self.dateStore && self.dateStore.isValidDate(new Date(e.start_at))
        )
      }
      const filterStaffMember = (events: Array<IEvent>) => {
        if (!staffMemberId) return events

        return events.filter(s => s.staff_members.find(sm => sm.id === staffMemberId))
      }

      return pipe(
        filteredEventsByService,
        filterValidDates,
        filterStaffMember,
        filterEventsByLocation
      )(self._events)
    }

    return {
      eventsFor,
      eventsByDay: (staffMemberId?: number) => {
        return groupBy<IEvent>(e => startOfDay(new Date(e.start_at)).toISOString())(
          eventsFor(staffMemberId)
        )
      },
      selectedWeekString,
      selectedDateString: () => format(self.selectedDate, 'EEEE, MMM do'),
      selectedDateShortString: () => format(self.selectedDate, 'EE MM/d')
    }
  })

export type IEvent = Instance<typeof Event>
export default EventStore
