import React from 'react'
import { IStore } from './Store'

let MobXProviderContext = React.createContext({})

export function useStore(): { store: IStore } {
  return React.useContext(MobXProviderContext) as { store: IStore }
}

export function Provider({ children, ...stores }: any) {
  const parentValue = React.useContext(MobXProviderContext)
  const value = React.useRef({
    ...parentValue,
    ...stores
  }).current

  return <MobXProviderContext.Provider value={value}> {children} </MobXProviderContext.Provider>
}
