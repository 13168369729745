import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { darken, desaturate } from 'polished'
import Row from '../presentational/Row'
import { useStore } from '../../store/Provider'
import { ISlot } from '../../store/AvailabilityStore'
import Loader from '../presentational/Loader'
import { lighten } from 'polished'

const ButtonInput = styled.input`
  text-decoration: none;
  padding: 12px;
  font-weight: bold;
  color: ${props => props.theme.textColor}
  cursor: pointer;
  background: ${props => props.theme.businessColor};
  border-radius: 50px;
  text-align: center;
  font-family: "ProximaNova-Semibold", "Helvetica Neue", Helvetica, Verdana, "Droid Sans";
  font-style: normal;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Verdana, 'Droid Sans';
  line-height: 14px;
  letter-spacing: 0.02em;
  color: white;
  border: none;

  &.pike13-schedule {
    @media (max-width: ${props => props.theme.mobileWidth}px) {
      width: 100%;
      height: auto;
      margin-left: -5px;
    }
  }

  &:hover {
    background: ${props => lighten(0.07, props.theme.businessColor)};;
  }

  ${props => props.theme.additionalButtonProperties};
`

const DateRange = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  color: #888;
  font-size: 0.8em;
  margin-right: 10px;

  div:first-child {
    font-size: 12px;
    color: ${props => darken(0.1, props.theme.textColor)};
  }
  div:last-child {
    opacity: 0.5;
    font-size: 10px;
    color: ${props => desaturate(0.6, props.theme.textColor)};
  }

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    padding-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start

    div:first-child {
      font-size: 14px;
      color: ${props => darken(0.1, props.theme.textColor)};
    }
    div:last-child {
      opacity: 0.5;
      font-size: 12px;
      color: ${props => desaturate(0.6, props.theme.textColor)};
    }
  
  }
  
`

const SlotDetails = styled.div`
  opacity: 0.5;
  color: ${props => desaturate(0.6, props.theme.textColor)};
  font-size: 12px;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    font-size: 10px;
  }
`

const DetailsButtonContainer = styled.div`
  display: flex;
  flex-grow: 8 !important;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    flex-direction: column;
  }
`
const DetailsContainer = styled.div`
  flex-grow: 8;

  div:first-child {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    div:first-child {
      font-size: 16px;
    }
  }
`

const ButtonContainer = styled.div`
  flex-grow: 1;
  aligh-self: center;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    form:first-child {
      width: 100%;
    }
  }
`


const Slot = ({ slot }: { slot: ISlot }) => {
  const { store } = useStore()
  const timezone = store.dateStore.timezone
  const subdomain = store.subdomain
  const startAtSeconds = new Date(slot.start_at).getTime() / 1000
  const location = store.locationStore.locationById(slot.location.id)
  const openInNewTab = store.openInTab ? '_blank' : ''

  const url = `https://${subdomain}.pike13.com/appointments/${slot.service.id}/appointment_enrollments?date=${slot.date}&location=${location?.slug}&service_id=${slot.service.id}&staff_member_id=${slot.staff_member.id}&start_at=${startAtSeconds}`

  if (store.availabilityStore.state === 'loading') return <Loader />

  return (
    <Row className="pike13-appointment">
      <DateRange>
        <div>{slot.startAt(timezone)}</div>
        <div>{slot.endAt(timezone)}</div>
      </DateRange>
      <DetailsButtonContainer>
        <DetailsContainer>
            <div>{slot.service.name}</div>
            <SlotDetails>
              {slot.staff_member.name && <span> with {slot.staff_member.name}</span>}
              {store && slot.location.name && <span> at {slot.location.name}</span>}
            </SlotDetails>
        </DetailsContainer>
          <ButtonContainer>
            <form action={url} method="post" target={openInNewTab}>
              <ButtonInput value="Book" type="submit" className="pike13-schedule"></ButtonInput>
            </form>
          </ButtonContainer>
      </DetailsButtonContainer>
    </Row>
  )
}

export default observer(Slot)
