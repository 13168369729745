import styled from 'styled-components'

const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  font-size: 0.8em;
  margin-bottom: 10px;

  @media (min-width: ${props => props.theme.mobileWidth}px) {
    padding-left: 0.8em;
    align-items: center;

    .react-datepicker-popper {
      margin-left: -15px;
    }

    > div {
      display: flex;
    }

    > div:last-child {
      width: 100%;
      justify-content: flex-end;
      padding-right: 1.8em;
    }
  }

  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    flex-direction: column;
    align-items: center;

    .react-datepicker-popper {
      margin-left: -25px;
    }

    > div {
      display: flex;
      justify-content: center;
    }

    > div:last-child {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .react-datepicker__input-container {
    display: none;
  }

  .react-datepicker {
    color: ${props => props.theme.textColor} !important;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin-top: 20px;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #545759;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    text-align: center;
    font-weight: lighter;
  }

  .react-datepicker__day--disabled {
    opacity: 0.2;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
    display: inline-block;
    width: 1.9rem;
    line-height: 1.9rem;
    text-align: center;
    margin: 0.196rem;
    font-size: 1em;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    font-weight: normal;
    font-size: 1.25em;
    color: white;
    margin-top: 2px;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__day--outside-month {
    color: rgba(255, 255, 255, 0.4);
  }

  .react-datepicker__day--highlighted {
    border-radius: 5px/5px;
    border 1px solid white;
    background: none;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    border-radius: 5px;
    background-color: white;
    color: #545759;
  }
  .react-datepicker__month,
  .react-datepicker__quarter {
    background-color: #545759;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0px;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: ${prop => prop.theme.borderColor};
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: ${prop => prop.theme.borderColor};
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
  }

  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
  }
`

export default CalendarWrapper
