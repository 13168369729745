import React from 'react'
import { observer } from 'mobx-react'
import { IStaffMember } from '../store/StaffMemberStore'
import styled from 'styled-components'
import Row from './presentational/Row'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Link from './presentational/Link'


const StaffPicture = styled.img`
  border-radius: 5px;

  @media (min-width: ${props => props.theme.mobileWidth}px) {
    width: 64px;
    height: 64px;
  }
  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    width: 126px;
    height: 126px;
  }
`

const StaffName = styled.div`
  display: flex;

  @media (min-width: ${props => props.theme.mobileWidth}px) {
    flex-grow: 2
    padding-left: 10px;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    flex-grow: 4
    margin: auto;
    font-size: 18px;
    align-items: center;
  }
`

const StaffButton = styled.button`
  display: flex;

  @media (min-width: ${props => props.theme.mobileWidth}px) {
    margin: auto;
    margin-right: 2em;
    padding: 17px 36px 17px 36px;
    font-size: 14px;
  }

  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    padding: 17px 27px 17px 27px;
    font-size: 14px;
    align-self: center;
  }
`
const StaffLink = StaffButton.withComponent(Link)

const StaffNameLink = styled.div`
  font-weight: bolder;
  @media (min-width: ${props => props.theme.mobileWidth}px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
`

const StaffMember = (props: { staff_member: IStaffMember } & RouteComponentProps) => {
  const staff_member = props.staff_member

  return (
    <Row>
      <StaffPicture alt={staff_member.name} src={staff_member.profilePhoto('x100')} />
      <StaffNameLink>
        <StaffName>{staff_member.name}</StaffName>
        <StaffLink to={`/staff/${staff_member.id}`}>View Profile</StaffLink>
      </StaffNameLink>
    </Row>
  )
}

export default withRouter(observer(StaffMember))
