import React, { useEffect, ComponentType, useState } from 'react'
import { Provider } from './store/Provider'
import Schedule from './components/Schedule/Schedule'
import StaffMembers from './components/StaffMembers'
import StaffMemberSchedule from './components/StaffMemberSchedule'
import Store, { IStore } from './store/Store'
import styled, { ThemeProvider } from 'styled-components'
import {
  HashRouter,
  MemoryRouter,
  Route,
  Redirect,
  Switch,
  RouteComponentProps
} from 'react-router-dom'
import Appointments from './components/Appointments/Appointments'
import StaffMemberProfile from './components/StaffMemberProfile'
import { ReactComponent as PoweredPike13 } from './components/presentational/p13logo.svg'
import { lighten } from 'polished'
import BrandedAppEditor from './components/BrandedAppEditor/index';
import { isInternal } from './util'

type IConfig = {
  component: string
  subdomain: string
  oauthToken?: string
  timezone?: string
  staffMemberId?: number
  locationIds?: Array<number>
  serviceIds?: Array<number>
  locationId?: number
  serviceId?: number
  useHashRoutes?: boolean
  openInTab?: boolean
  date?: {
    start?: number | Date
    end?: number | Date
  }
  mediaQueries: {
    tinyMediaWidth: number,
    mobileWidth: number,
  },
  style?: {
    textColor?: string
    borderColor?: string
    backgroundColor?: string
    buttonBackgroundColor?: string
    businessColor?: string
  },
  defaultWidgetView: 'week' | 'day'
}

const WidgetWrapper = styled.div`
  font-family: 'ProximaNova-Semibold', 'Helvetica Neue', Helvetica, Verdana, 'Droid Sans';
  color: ${props => props.theme.textColor};
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  min-width: 320px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const internal = isInternal(window);

function App(props: IConfig & RouteComponentProps) {
  const style = !!props.style ? props.style : {}
  const Router: ComponentType<{}> = props.useHashRoutes ? HashRouter : MemoryRouter
  let [store, setStore] = useState<IStore | null>(null)
  
  // const activeTextColor = readableColor(theme.businessColor, 'white', 'black')
  const businessColor = style.businessColor ? style.businessColor : '#4c9d2f'
  const theme = {
    textColor: style.textColor ? style.textColor : '#545759',
    buttonTextColor: '#fff',
    businessColor,
    disabledButtonColor: lighten(0.1, businessColor),
    borderColor: style.borderColor ? style.borderColor : '#bfbfbf',
    backgroundColor: style.backgroundColor ? style.backgroundColor : '#fff',
    buttonBackgroundColor: style.buttonBackgroundColor ? style.buttonBackgroundColor : '#bfbfbf',
    ...props.mediaQueries
  }

  useEffect(() => {
    const store = Store.create({
      state: 'loading',
      eventStore: {
        serviceIds: props.serviceIds,
        locationIds: props.locationIds
      },
      staffMemberStore: {
        id: '1',
        configuredStaffMemberId: props.staffMemberId
      },
      serviceStore: {
        id: '1',
        configuredServiceIds: props.serviceIds
      },
      locationStore: {
        id: '1',
        configuredLocationIds: props.locationIds
      },
      availabilityStore: {},
      dateStore: {
        id: '1',
        timezone: props.timezone,
        ...props.date
      },
      subdomain: props.subdomain,
      oauthToken: !!props.oauthToken ? props.oauthToken : '',
      openInTab: window.location !== window.parent.location ? true : props.openInTab,
      widgetView: props.defaultWidgetView,
      internal: internal
    })
    store.fetchBusiness()
    store.staffMemberStore.fetchStaffMembers()
    store.brandingStore.fetchBranding()
    setStore(store)
  }, [
    props.subdomain,
    props.timezone,
    props.staffMemberId,
    props.locationIds,
    props.serviceIds,
    props.date,
    props.openInTab,
    props.oauthToken,
    props.defaultWidgetView
  ])

  if (!store) {
    return <div></div>
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <WidgetWrapper className="pike13-widget">
          <Router>
            <Switch>
              <Route path="/staff/:id/schedule/:viewType">
                <StaffMemberSchedule />
              </Route>
              <Route path="/staff/:id/schedule">
                <StaffMemberSchedule />
              </Route>
              <Route path="/staff/:id">
                <StaffMemberProfile />
              </Route>
              <Route path="/staff">
                <StaffMembers />
              </Route>
              <Route path="/schedule/week">
                <Schedule />
              </Route>
              <Route path="/schedule">
                <Schedule />
              </Route>
              <Route path="/appointments">
                <Appointments />
              </Route>
              <Route path="/branded-app-editor">
                <BrandedAppEditor />
              </Route>
              <Redirect to={`/${props.component}`}></Redirect>
            </Switch>
          </Router>
          {!store.internal &&
          (<LogoContainer>
            <a href="http://pike13.com" target="_blank" rel="noopener noreferrer">
              <PoweredPike13 />
            </a>
          </LogoContainer>)}
        </WidgetWrapper>
      </ThemeProvider>
    </Provider>
  )
}

export default App
