import styled from 'styled-components'

const SmallMobile = styled.div`
  display: inline;

  @media (min-width: ${props => props.theme.tinyMobileWidth}px) {
    display: none;
    border: 1px solid green;
  }
`

const NonSmallMobile = styled.div`
  display: inline;

  @media (max-width: ${props => props.theme.tinyMobileWidth - 1}px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: inline;

  @media (min-width: ${props => props.theme.mobileWidth}px) {
    display: none;
    border: 1px solid green;
  }
`
const Desktop = styled.div`
  display: inline;

  @media (max-width: ${props => props.theme.mobileWidth - 1}px) {
    display: none;
    border: 1px solid green;
  }
`
const Only = {
  Desktop,
  Mobile,
  SmallMobile,
  NonSmallMobile
}

export default Only
