export const THEMES = {
  "pike-green": {
    "primaryColor": "#00833d",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#e9edee",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#ffffff",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#00833d",
    "critical": "#eb0000",
    "warningText": "#ac5d00",
    "warningIcon": "#dc8400",
    "interactivePrimary": "#00833d",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "crimson": {
    "primaryColor": "#c3362d",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#e9edee",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#ffffff",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#00833d",
    "critical": "#eb0000",
    "warningText": "#ac5d00",
    "warningIcon": "#dc8400",
    "interactivePrimary": "#c3362d",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "forrest": {
    "primaryColor": "#0a3e22",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#e9e7e3",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#ffffff",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#00833d",
    "critical": "#eb0000",
    "warningText": "#ac5d00",
    "warningIcon": "#dc8400",
    "interactivePrimary": "#0a3e22",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "Vineyard": {
    "primaryColor": "#524273",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#efebee",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#ffffff",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#008A10",
    "critical": "#ea0b0b",
    "warningText": "#b35f00",
    "warningIcon": "#b35f00",
    "interactivePrimary": "#524273",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "Southwest": {
    "primaryColor": "#F3EDE7",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#F3EDE7",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#A23434",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#008a10",
    "critical": "#ea0b0b",
    "warningText": "#b35f00",
    "warningIcon": "#b35f00",
    "interactivePrimary": "#A23434",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "Twilight": {
    "primaryColor": "#E9EDEE",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#E9EDEE",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#080085",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#080085",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#3C3B54",
    "onDisabledBackground": "#707070",
    "success": "#006630",
    "critical": "#ea0b0b",
    "warningText": "#b35f00",
    "warningIcon": "#b35f00",
    "interactivePrimary": "#080085",
    "interactiveSecondary": "#3C3B54",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "Dark": {
    "primaryColor": "#000000",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#000000",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#000000",
    "surface": "#2f2f2f",
    "onPrimary": "#ffffff",
    "primaryOnBackground": "#ffffff",
    "primaryOnSurface": "#ffffff",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#ffffff",
    "secondaryOnSurface": "#B0B0B0",
    "onDisabledBackground": "#707070",
    "success": "#09dc5a",
    "critical": "#ff6176",
    "warningText": "#f79708",
    "warningIcon": "#f79708",
    "interactivePrimary": "#ffffff",
    "interactiveSecondary": "#B0B0B0",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  },
  "Light": {
    "primaryColor": "#ffffff",
    "secondaryColor": "#81bc00",
    "backgroundColor": "#f5f5f5",
    "disabledBackground": "#f4f4f4",
    "onInteractivePrimary": "#ffffff",
    "surface": "#ffffff",
    "onPrimary": "#000000",
    "primaryOnBackground": "#000000",
    "primaryOnSurface": "#000000",
    "onSecondary": "#000000",
    "secondaryOnBackground": "#707070",
    "secondaryOnSurface": "#707070",
    "onDisabledBackground": "#707070",
    "success": "#00833d",
    "critical": "#eb0000",
    "warningText": "#ac5d00",
    "warningIcon": "#dc8400",
    "interactivePrimary": "#000000",
    "interactiveSecondary": "#707070",
    "divider": "#c2c2c4",
    "banner": "#e5f0ea"
  }
}