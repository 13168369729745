import styled from 'styled-components'
import { lighten } from 'polished'

const Button = styled.a`
  text-decoration: none;
  padding: 12px;
  font-weight: bold;
  color: ${props => props.theme.textColor}
  cursor: pointer;
  background: ${props => props.theme.businessColor};
  border-radius: 50px;
  text-align: center;
  font-family: "ProximaNova-Semibold", "Helvetica Neue", Helvetica, Verdana, "Droid Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: white;
  min-width: 60px;

  &.pike13-schedule {
    @media (max-width: ${props => props.theme.mobileWidth}px) {
      width: 100%;
      height: auto;
      margin-left: -5px;
    }
  }

  &.disabled{
    background: none;
    color: black;
    &:hover {
      background: none;
    }
  }

  &:hover {
    background: ${props => lighten(0.07, props.theme.businessColor)};
    color: ${props => props.theme.textColor}
  }

  &disabled {
    background: #E7E7E7;
    border-radius: 50px;
    color: rgba(87, 87, 87, 0.36);
  }

  &.pike13-back-button {
    background-color: transparent;
    color: #555;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }
`

export default Button
