import { types } from "mobx-state-tree"
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import { addDays, isWithinInterval, startOfDay, endOfDay } from "date-fns"

function formattedTimezone(timezone: string, formatting: string) {
  return format(utcToZonedTime(toDate(new Date(), { timeZone: timezone }), timezone), formatting, { timeZone: timezone })
}

type viewType = "day" | "week"

const DateStore = types.model({
  id: types.identifier,
  browserTimeZone: types.optional(
    types.string,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ),
  timezone: types.maybe(types.string),
  start: types.optional(types.union(types.number, types.Date), addDays(new Date(), -90)),
  end: types.optional(types.union(types.number, types.Date), addDays(new Date(), 365)),
  weekdays: types.maybe(types.array(types.number))
}).views(self => {
  const startDate = typeof (self.start) === "number" ?
    addDays(startOfDay(new Date()), self.start) :
    self.start

  const endDate = typeof (self.end) === "number" ?
    addDays(startOfDay(new Date()), self.end) :
    self.end

  // since endDate starts at midnight on the day we care about, when
  // comparing events we need to look at 11: 59: 59 PM that day
  const endTime = endOfDay(endDate)

  const dateInRange = (date: Date) => {
    return isWithinInterval(date, {
      start: startDate,
      end: endTime
    })
  }

  const isValidDate = (date: Date) => {
    if (self.weekdays && !self.weekdays.includes(date.getDay())) return false;

    return dateInRange(date)
  }

  const nextValidDateFor = (date: Date, offset: number) => {
    let possibleNextDate = addDays(date, offset)

    while (!isValidDate(possibleNextDate) && dateInRange(possibleNextDate)) {
      possibleNextDate = addDays(possibleNextDate, offset)
    }

    if (isValidDate(possibleNextDate)) return possibleNextDate
  }

  return {
    timeZoneDisplay: (displayFormat = "zzzz") => {
      const timezone = self.timezone
      if (!timezone) return undefined

      const browserTZCode = formattedTimezone(self.browserTimeZone, "zzz")
      const componentTZCode = formattedTimezone(timezone, "zzz")
      const displayTimezone = browserTZCode !== componentTZCode

      if (!displayTimezone) return undefined

      return formattedTimezone(timezone, displayFormat)
    },
    isValidDate,
    startDate: () => startDate,
    endTime: () => endTime,
    nextDate: (date: Date, type: viewType = "day") => {
      const offset = type === "week" ? 7 : 1
      return nextValidDateFor(date, offset)
    },
    previousDate: (date: Date, type: viewType = "day") => {
      const offset = type === "week" ? -7 : -1
      return nextValidDateFor(date, offset)
    }
  }
}).actions(self => {
  return {
    setTimezone: (timezone: string) => {
      self.timezone = timezone
    }
  }
})

export default DateStore
