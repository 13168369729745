import React, { useEffect, useState } from 'react'
import Event from './Event'
import { observer } from 'mobx-react'
import { IEvent } from '../../store/EventStore'
import { format } from 'date-fns'
import Header from '../presentational/Header'
import Loader from '../presentational/Loader'
import { useStore } from '../../store/Provider'
import styled from 'styled-components'
import Button from '../presentational/Button'

const None = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

const EventGroup = ({ date, events }: { date?: string; events: Array<IEvent> }) => {
  const [collapsed, setCollapsed] = useState(true)
  const { eventStore } = useStore().store
  const pastEvents = events.filter(event => event.inPast())
  const upcomingEvents = events.filter(event => event.inPast() === false)
  const showPastEvents = pastEvents.length !== events.length || eventStore.viewType === 'week'

  const collapseEvents = pastEvents.length > 1 && showPastEvents && collapsed
  return (
    <div
      style={{
        padding: '2px'
      }}
    >
      {date && (
        <Header className="pike13-event-date-header">
          {format(new Date(date), 'EEEE MMMM dd, y')}
        </Header>
      )}
      {collapseEvents && (
        <>
          <Event
            key={pastEvents[0].id}
            event={pastEvents[0]}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            count={pastEvents.length}
          />
          {upcomingEvents.map((e: IEvent) => (
            <Event key={e.id} event={e} setCollapsed={setCollapsed} />
          ))}
        </>
      )}
      {!collapseEvents && (
        <>
          {events.map((e: IEvent) => (
            <Event key={e.id} event={e} setCollapsed={setCollapsed} />
          ))}
        </>
      )}
    </div>
  )
}

const Events = ({ staffMemberId }: { staffMemberId?: number }) => {
  const { eventStore } = useStore().store

  const [searchStartDate, setSearchStartDate] = useState<Date | undefined>(undefined)
  const [nextAvailableDate, setNextAvailableDate] = useState<Date | null | undefined>(undefined)

  useEffect(() => {
    if (nextAvailableDate === null && searchStartDate !== eventStore.selectedDate) {
      setNextAvailableDate(undefined)
    }
  }, [eventStore.selectedDate, searchStartDate, nextAvailableDate])

  if (eventStore.state === 'loading' || eventStore.state === 'preloading') {
    return <Loader />
  }

  if (eventStore.viewType === 'week') {
    const eventsByDay = Object.keys(eventStore.eventsByDay(staffMemberId))

    if(!eventsByDay.length) {
      return (
        <None className="pike13-notice">
          {`No Classes between ${eventStore.selectedWeekString()}`}
        </None>
      )
    }
    return (
      <div>
        {Object.keys(eventStore.eventsByDay(staffMemberId))
          .sort()
          .map(e => {
            return (
              <EventGroup key={e} date={e} events={eventStore.eventsByDay(staffMemberId)[e]} />
            )
          })}
      </div>
    )
  }

  const events = eventStore.eventsFor(staffMemberId)

  if (!events.length) {
    return (
      <None className="pike13-notice">
        {`No Classes ${nextAvailableDate === null || nextAvailableDate === eventStore.selectedDate ? `available` : ` on ${eventStore.selectedDateString()}`}`}
        {nextAvailableDate !== null && nextAvailableDate !== eventStore.selectedDate && (
          <Button
            style={{
              width: 'auto',
              margin: '16px 0px',
              padding: '6px 10px',
              fontSize: '14px'
            }}
            onClick={async () => {
              setSearchStartDate(eventStore.selectedDate)
              const date = await eventStore.nextAvailableDate(staffMemberId)
              if (!date) {
                setNextAvailableDate(null)
                eventStore.setState('done') // HACKY, but ensuring render behavior... So maybe not so bad?
              } else {
                setNextAvailableDate(date)
                eventStore.setDate(date)
              }
            }}>
            Next Available
          </Button>
        )}
      </None>
    )
  }

  return <EventGroup key={events[0].id} events={events} />
}

export default observer(Events)
