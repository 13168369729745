import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Loader from '../presentational/Loader'
import { useStore } from '../../store/Provider'
import { ISlot } from '../../store/AvailabilityStore'
import Slot from './Slot'
import styled from 'styled-components'
import Button from '../presentational/Button'

const None = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

const Slots = () => {
  const { availabilityStore, locationStore, serviceStore } = useStore().store

  const [searchStartDate, setSearchStartDate] = useState<Date | undefined>(undefined)
  const [nextAvailableDate, setNextAvailableDate] = useState<Date | null | undefined>(undefined)

  useEffect(() => {
    availabilityStore.fetch()
  }, [availabilityStore, serviceStore, locationStore])

  useEffect(() => {
    if (nextAvailableDate === null && searchStartDate !== availabilityStore.selectedDate) {
      setNextAvailableDate(undefined)
    }
  }, [availabilityStore.selectedDate, searchStartDate, nextAvailableDate])

  if (availabilityStore.state === 'loading') {
    return <Loader />
  }

  if (!availabilityStore.hasAvailability()) {
    return (
      <None className="pike13-notice">
        {`No availability ${nextAvailableDate === null || nextAvailableDate === availabilityStore.selectedDate ? `` : `on ${availabilityStore.selectedDateString()}`}`}
        {nextAvailableDate !== null && nextAvailableDate !== availabilityStore.selectedDate && (
          <Button
            style={{
              width: 'auto',
              margin: '16px 0px',
              padding: '6px 10px',
              fontSize: '14px'
            }}
            onClick={async () => {
              setSearchStartDate(availabilityStore.selectedDate)
              let daysFromNow = 1
              let foundNextDate = false
              // would love to loop in the store, but MobX doesn't like it
              while (daysFromNow < 22 && !foundNextDate) {
                const date = await availabilityStore.nextAvailableDate(daysFromNow)
                if (!!date) {
                  setNextAvailableDate(date)
                  availabilityStore.setDate(date)
                  foundNextDate = true
                } else {
                  if (daysFromNow === 21) {
                    setNextAvailableDate(null)
                    availabilityStore.setState('done')
                  }
                  daysFromNow++
                }
              }
            }}>
              Next Available
            </Button>
        )}
      </None>
    )
  }

  return (
    <div>
      {availabilityStore.upcomingSlots().map((s: ISlot) => (
        <Slot slot={s} key={s.key()} />
      ))}
    </div>
  )
}

export default observer(Slots)
