import styled from "styled-components";

export const Root = styled.div`
  @import url("https://use.typekit.net/drz5eia.css");
  font-family: "Nunito", sans-serif;

  background-color:  #c8d3d6;
  display: flex;
  justify-content: space-evenly;

  min-width: 100%;
  min-height: 100%;

  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 48px;
    color: #000000;
  }

  a {
    color: #00833d;
    cursor: pointer;
    text-decoration: none;
  }

  a.disabled {
    color: #aaaaaa !important;
    cursor: default !important;
  }

  .preview-control {
    display: flex;
    justify-content: center;
    margin-top: 2.5em;
    margin-bottom: 1em;
  }

  .preview-control button {
    font-size: 24px;
    cursor: pointer;
    background: none;
    margin: 0;
    padding: 0;
    margin-right: 0.5em;
    margin-left: 0.5em;
    border: 0;
    color: #00833d;
    margin-top: -0.15em;
  }
  
  .preview-control button.disabled {
    font-size: 24px;
    cursor: default;
    color: #aaaaaa;
  }

  p {
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  button.primary {
    height: 52px;
    width: 142px;
    border-radius: 8px;
    background-color: #00833d;
    color: #ffffff;
    cursor: pointer;
    border: none;
    font-size: 20px;
  }

  button.primary.disabled {
    height: 52px;
    width: 142px;
    border-radius: 8px;
    background-color: #aaaaaa;
    color: #ffffff;
    cursor: default;
    border: none;
  }

  .secondary-cta {
    font-size: 20px;
    color: #707070;
    background: transparent;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-right: 1em;
    padding: 14px 0px;
    text-decoration: none;
    border: 0;
  }

  @media only screen and (max-width: 650px) {
    display: block;
    justify-content: space-evenly;

    #themes {
      padding: 1em;
    }

    button.primary {
      font: "Nunito";
      height: 32px;
      width: 75px;
      border-radius: 8px;
      background-color: #00833d;
      color: #ffffff;
      cursor: pointer;
      border: none;
      font-size: 16px;
      font-weight: bold;
    }

    button.primary.disabled {
      background-color: #aaaaaa;
      color: #ffffff;
      cursor: default;
      border: none;
    }

    .secondary-cta {
      font-size: 16px;
    }
  }
`;

export const TopNav = styled.div`
  font-family: "Nunito", sans-serif;
  position: relative;
  min-width: 100%;
  background-color: #00833d;
  min-height: 100%;
`

export const NavBar = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  .nav-button {;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
  }
  .active {
    background-color: #84b795
  }
  button {
    border: 0;
    font-weight: bold;
    color: #fff;
    background-color: transparent;
    margin-left: 1em;
  }
`

export const StatusText = styled.div`
  font-family: "Nunito", sans-serif;
  color: #00833d;
  font-size: 18px;
  text-align: left;
`

export const Editor = styled.div`
  font-family: "Nunito", sans-serif;
  flex: 2;
`;

export const ImageHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Preview = styled.div`
  font-family: "Nunito", sans-serif;
  flex: 1;
  margin-left: 2em;
`;

export const Previews = styled.div`
  * {
    max-height: 60%;
    max-width: 55%;
    text-align-last: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

export const Swatches = styled.div`
  font-family: "Nunito", sans-serif;
  flex: 1;
  padding: 1em;

  @media only screen and (max-width: 650px) {
    width: 100%;
    margin: 0;
    padding: 0em;
  }
`;

export const SwatchesContainer = styled.div`
  font-family: "Nunito", sans-serif;
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  a {
    cursor: pointer;
  }
`;

export const Swatch = styled.div`
  font-family: "Nunito", sans-serif;
  position: relative;
  width: 1/3;
  height: 70px;
  margin-right: 0.25em;
  margin-left: 0.25em;
  text-transform: capitalize;

  input {
    width: 263px;
    height: 64px;
    cursor: pointer;
  }

  label {
    position: absolute;
    width: 100%;
    height: 64px;
    left: 0.5em;
    top: 0.4em;
    pointer-events: none;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0.2em;
  }

  .badge {
    top: 35px;
    left: 0.7em;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    pointer-events: none;
  }

  .value {
    position: absolute;
    top: 2.5em;
    width: 94%;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: right;
    pointer-events: none;
    text-transform: uppercase;
    padding: 0.2em;
  }

  @media only screen and (max-width: 650px) {
    position: relative;
    width: 100%;
    height: 70px;
    margin-right: 0.25em;
    margin-left: 0.25em;
    text-transform: capitalize;

    input {
      width: 100%;
      height: 64px;
      cursor: pointer;
    }
  }
`;

export const SwatchControls = styled.div`
  font-family: "Nunito", sans-serif;
  margin-top: 1em;
  align-content: center;

  div {
    margin-top: 1em;
  }

  a {
    cursor: pointer;
    margin-right: 4em;
  }
`;

export const ThemesContainer = styled.div`
  font-family: "Nunito", sans-serif;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`;

export const ThemeElement = styled.div`
  font-family: "Nunito", sans-serif;
  position: relative;
  background-color: #ffffff;
  width: 263px;
  height: 64px;
  margin: 0.5em;
  border: 0.25px solid #aaa;

  label {
    position: absolute;
    width: 100%;
    margin-left: 1em;
    margin-top: 0.5em;
    font-weight: 700;
  }

  .swatches {
    position: absolute;
    margin-top: 2em;
    margin-left: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .swatch {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    pointer-events: none;
    border: 0.25px solid #aaa;
  }

  button {
    position: absolute;
    text-align: right;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
    right: 1.5em;
    top: 2.5em;
    cursor: pointer;
  }

  @media only screen and (max-width: 650px) {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    height: 64px;
    margin: 0.5em;
    margin-left: 0;
    border: 0.25px solid #aaa;
  }
`;

export const Nav = styled.nav`
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  ul {
    font-size: 24px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: none;
    border: 0;
    border-bottom: 1px solid #c4c4c4;
  }

  li {
    float: left;
    margin: 0 36px 0 0px;
    border: 0;
    button {
      border: 0;
      display: block;
      color: #707070;
      background: transparent;
      text-align: center;
      padding: 0;
      margin: 0;
      padding: 14px 0px;
      text-decoration: none;
      
    }
  }

  li:last-child {
    float: right;
    margin: 0;
  }

  li:last-child button {
    color: #00833d;
  }

  li button.active {
    color: #00833d;
    border-bottom: 1px solid #00833d;
  }

  @media only screen and (max-width: 650px) {
    ul {
      font-size: 80%;
    }
  }
`;

export const Center = styled.div`
  * {
    margin: 0 auto;
  }
`;

export const UploaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const AssetUploaderContainer = styled.div`
  margin-bottom: 12px;
  margin-right: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  width: 275px;
  border-radius: 6px;
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  background-color: #f6f6f6;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #dedede;
`;

export const FileOpsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 3px;
  max-width: 200px;
  height: 100%;
`;

export const FileInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
`;

export const AssetInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  div {
    margin-bottom: 2px;
  }
`;

export const FileOpsButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  background-color: #a0a0a0;
  color: #fff;
  border-radius: 6px;
  width: 106px;
`;
