import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Only from './presentational/Only'
import DatePicker from 'react-datepicker'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { useStore } from '../store/Provider'
import { Link } from 'react-router-dom'
import CalendarWrapper from './presentational/CalendarWrapper'
import { format } from 'date-fns-tz'
import { max } from 'date-fns'

const CalendarButton = styled.button`
  flex: 1;
  display: flex;
  margin: 0px;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  font-weight: bolder;
  margin-bottom: 10px;
  text-align: center;
  align-content: center;
  padding: 10px 12px;
  align-items: center;
  color: #fff;
  background-color: ${props => props.theme.businessColor};

  &.pike13-disabled {
    background-color: ${props => props.theme.disabledButtonColor};
  }

  &:first-child {
    border-radius: 90px 0 0 90px;
  }

  &:last-child {
    border-radius: 0 90px 90px 0;
  }
`
const CalendarArrowDiv = styled.div`
  cursor: pointer;
  text-decoration: none;
  font-weight: bolder;
  text-align: center;
  padding: 12px;
`

const DisabledCalendarArrowDiv = styled.div`
  cursor: pointer;
  text-decoration: none;
  font-weight: bolder;
  text-align: center;
  padding: 12px;
`

const CalendarDiv = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  font-weight: bolder;
  text-align: center;
  align-content: center;
  align-items: center;
  padding: 10px 12px;
`

const CalendarTimezone = styled.div`
  padding: 10px 12px;
`

const DateDisplay = styled.span`
  display: inline-block;
  text-align: left;
  padding: 4px;
  width: 135px;
`

const CalendarLink = CalendarButton.withComponent(Link)

const CalendarTimezonePill = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    flex-direction: column;
  }
`

type selectedType = 'week' | 'day'
const CalendarToggleButton = observer(
  withRouter(
    withTheme(
      (
        props: {
          type: selectedType
          selected: selectedType
          theme?: any
        } & RouteComponentProps
      ) => {
        const store = useStore().store
        const { setWidgetView } = store
        const { type, selected } = props
        const typeString = type.charAt(0).toUpperCase() + type.slice(1)

        const endUrl = type === 'week' ? '/week' : ''
        const url = props.location.pathname.replace('/week', '') + endUrl

        return (
          <CalendarLink
            className={`pike13-calendar-button ${selected !== type ? '' : 'pike13-disabled'}`}
            to={url}
            onClick={() => { 
              setWidgetView(type)
            }}
          >
            {typeString}
          </CalendarLink>
        )
      }
    )
  )
)

const Calendar = ({
  onSetDate,
  selectedDate,
  viewType,
  showWeekTab,
  startDate
}: {
  selectedDate: Date
  onSetDate: (date: Date) => void
  startDate?: Date
  viewType?: 'day' | 'week'
  showWeekTab?: boolean
}) => {
  const { dateStore } = useStore().store
  const getPreviousDay = () => {
    const previous = dateStore.previousDate(selectedDate, viewType)
    if (typeof previous === 'undefined' || !startDate) return previous

    if (startDate >= selectedDate) return undefined

    return max([previous, startDate])
  }

  const nextDay = dateStore.nextDate(selectedDate, viewType)
  const previousDay = getPreviousDay()

  const dateBack = () => previousDay && onSetDate(previousDay)
  const dateForward = () => nextDay && onSetDate(nextDay)
  const pickDate = React.createRef<DatePicker>()
  const selectedViewType = viewType ? viewType : 'day'

  const focusDatePicker = () => {
    if (!pickDate.current) return
    pickDate.current.setFocus()
    pickDate.current.setOpen(true)
    return false
  }

  const closeDatePicker = () => {
    if (!pickDate.current) return
    pickDate.current.setOpen(false)
    pickDate.current.forceUpdate()
  }

  const setDate = (date: Date, event: React.SyntheticEvent<any>) => {
    event.stopPropagation()
    onSetDate(date)
    closeDatePicker()
  }

  const selectedDateString = format(selectedDate, 'EEEE, MMM do')

  const filterDate = (date: Date) => {
    if (startDate && startDate > date) return false
    return dateStore.isValidDate(date)
  }

  return (
    <CalendarWrapper className="pike13-date-picker">
      <div>
        <CalendarDiv onClick={focusDatePicker}>
          <DatePicker
            onClickOutside={closeDatePicker}
            onBlur={closeDatePicker}
            onChange={setDate}
            onSelect={setDate}
            filterDate={filterDate}
            selected={selectedDate}
            highlightDates={[new Date()]}
            ref={pickDate}
          />
          <FaRegCalendarAlt style={{ paddingRight: '4px', marginBottom: '4px' }} />
          <DateDisplay data-testid="selected-date">{selectedDateString}</DateDisplay>
        </CalendarDiv>
        {previousDay ? (
          <CalendarArrowDiv data-testid="previous-day" onClick={dateBack}>
            &lt;
          </CalendarArrowDiv>
        ) : (
          <DisabledCalendarArrowDiv data-testid="disabled-previous-day">
            &lt;
          </DisabledCalendarArrowDiv>
        )}
        {nextDay ? (
          <CalendarArrowDiv data-testid="next-day" onClick={dateForward}>
            &gt;
          </CalendarArrowDiv>
        ) : (
          <DisabledCalendarArrowDiv data-testid="disabled-next-day">
            &gt;
          </DisabledCalendarArrowDiv>
        )}
      </div>
      <CalendarTimezonePill>
        {dateStore.timeZoneDisplay() && (
          <CalendarTimezone>
            <Only.Desktop>{'Times shown in ' + dateStore.timeZoneDisplay()}</Only.Desktop>
            <Only.Mobile>{dateStore.timeZoneDisplay('zzz')}</Only.Mobile>
          </CalendarTimezone>
        )}
        <div>
          <div style={{ display: 'flex' }}>
            {!!showWeekTab && (
              <>
                <CalendarToggleButton type="week" selected={selectedViewType} />
                <CalendarToggleButton type="day" selected={selectedViewType} />
              </>
            )}
          </div>
        </div>
      </CalendarTimezonePill>
    </CalendarWrapper>
  )
}
export default observer(Calendar)
