import React from 'react'
import { observer } from 'mobx-react'
import { IEvent } from '../../store/EventStore'
import styled from 'styled-components'
import { darken, desaturate } from 'polished'
import Row from '../presentational/Row'
import Button from '../presentational/Button'
import { useStore } from '../../store/Provider'
import Only from '../presentational/Only'

const DateRange = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  color: #888;
  margin-right: 10px;

  div:first-child {
    font-size: 12px;
    color: ${props => darken(0.1, props.theme.textColor || 'black')};
  }
  div:last-child {
    font-size: 10px;
    opacity: 0.5;
    color: ${props => desaturate(0.6, props.theme.textColor || 'black')};
  }

  &.pike13-in-past {
    opacity: 0.5;
  }

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    grid-column: 1;

    div:first-child {
      font-size: 14px;
    }
    div:last-child {
      font-size: 12px;
    }
  }
`

const EventDetails = styled.div`
  padding: 4px 0 0 0;
  opacity: 0.5;
  color: ${props => desaturate(0.6, props.theme.textColor || 'black')};
  font-size: 12px;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    font-size: 10px;
  }
`

const EventDetailsContainer = styled.div`
  display: flex;
  flex-grow: 8 !important;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    flex-direction: column;
  }
`

const DetailsContainer = styled.div`
  width: 70%;

  div:first-child {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    margin-left: 10px;

    div:first-child {
      font-size: 16px;
    }
  }
`

const ButtonContainer = styled.div`
  flex-grow: 1;
  aligh-self: center;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    form:first-child {
      width: 100%;
    }
  }
`

const Capacity = styled.div`
  flex-grow: 1;
  align-self: center;
  align-items: center;
  width: 50px;
  justify-content: flex-end;
  display: flex;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    grid-column: 1;
    display: flex;
    margin-bottom: 30px;
  }
`

const DateCapacityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`

const CapacityText = styled.span`
  font-size: 14px;
`

const EnrollContainer = styled.div`
  flex-grow: 1;
  align-self: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    justify-content: center;
  }
`

const CollapsedEventCount = styled.div`
  margin-right: 25px;

  @media (max-width: ${props => props.theme.mobileWidth}px) {
    justify-content: center;
  }
`

const Event = ({
  event,
  collapsed,
  setCollapsed,
  count
}: {
  event: IEvent
  collapsed?: boolean
  setCollapsed?: any
  count?: number
}) => {
  const { store } = useStore()
  const timezone = store.dateStore.timezone
  const locationStore = store.locationStore
  const location = locationStore.locationById(event.location_id)
  const openInNewTab = store.openInTab ? '_blank' : ''

  const pastClass = event.inPast() ? (collapsed ? 'pike13-collapsed' : 'pike13-past') : ''
  const className = `pike13-event ${pastClass}`.trimRight()

  return (
    <Row className={className} onClick={() => setCollapsed(!collapsed)}>
      <DateCapacityContainer>
        <DateRange className={`pike13-daterange ${event.inPast() ? 'pike13-in-past' : ''}`}>
          <div>{event.startAt(timezone)}</div>
          <div>{event.endAt(timezone)}</div>
        </DateRange>
        <Only.Mobile>
          <Capacity>
            {event.showCapacity() && (
              <CapacityText>{event.capacity_remaining} Left</CapacityText>
            )}
          </Capacity>
        </Only.Mobile>
      </DateCapacityContainer>
      <EventDetailsContainer>
        <DetailsContainer>
          <div>{event.name}</div>
          <EventDetails>
            {event.staff_members.length > 0 && <span> with {event.staffMembers()}</span>}
            {store && locationStore.multipleLocations() && location && (
              <span> at {location.name}</span>
            )}
          </EventDetails>
        </DetailsContainer>
        <Capacity>
          <Only.Desktop>
            {event.showCapacity() && (
              <CapacityText>{event.capacity_remaining} Left</CapacityText>
            )}
          </Only.Desktop>
        </Capacity>
        <ButtonContainer>
          <EnrollContainer>
            &nbsp;
            {event.isCanceled() && !collapsed && <Button className="disabled">Canceled</Button>}
            {((event.isFull() && (!event.waitlist || event.waitlist?.isFull()) && !collapsed)) && <Button className="disabled">Full</Button>}
            {event.isEnrollable() ? (
              <Button
                href={event.url}
                target={openInNewTab}
                className="pike13-button pike13-schedule"
              >
                Enroll
              </Button>
            ) : !!event.waitlist && !event.waitlist?.isFull() && !event.isCanceled() && (
              <Button
                href={event.url}
                target={openInNewTab}
                className="pike13-button pike13-schedule"
              >
                Join Waitlist
              </Button>
            )}
            {collapsed && count && count > 1 && (
              <CollapsedEventCount>+{count ? count - 1 : ''}</CollapsedEventCount>
            )}
          </EnrollContainer>
        </ButtonContainer>
      </EventDetailsContainer>
    </Row>
  )
}

export default observer(Event)
